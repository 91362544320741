import { useEffect } from 'react';
import ResumePDF from '../../static/Anubhav_Bagri_Resume.pdf';

const ResumePage = () => {
  useEffect(() => {
    window.location.replace(ResumePDF); // Replaces history entry instead of adding a new one
  }, []);

  return null; // No need to render anything
};

export default ResumePage;
